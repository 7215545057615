// import { Tooltip } from "@material-ui/core";
import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import DataGroupings from "../components/data-groupings/data-groupings";
import ProductChartInfo from "../components/product-chart-info/product-info-chart";
import request from "../helpers/request";
import GenericDataRow from "./GenericDataRow";
const localised = Intl.NumberFormat("en-GB");

export const SharedChartColours = [
  "#445469",
  "#33d1ad",
  "#0d73b2",
  "#f19a14",
  "#d13358",
  "#b24c0d",
  "#DFBE99",
  "#C879FF",
  "#DEC5E3",
  "#0D1821",
  "#F9CB40",
];

const LoadingForGraphTable = () => {
  return (
    <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', margin: '0 auto'}}>
      <i className="fas fa-spinner fa-pulse fa-4x colour-gray"></i>
    </div>
  );
};

export default function GenericDataChart({
  groupingsEndpoint,
  defaultGroupBy = null,
  totalColumns = 1,
  excludeColumnsFromTotal = [],
  starColumnHeaders = false, // Display 0-5 Occupier Score headers as star ratings
  data = null,
  dataReady = false,
  selectedGroupBy,
  setSelectectGroupBy,
  displayModeInitial = "graph",
  prefix = '',
  parentHandler = null,
  chartFunctions = [],

  sizes = {
    chartContainer: 625,
    chart: 610,
    chartXAxisFontSize: 12,
    wrapperStyle: {
      paddingTop: "25px",
    }
  },

  customBarTick,
  xAxisProps = {},

  overrideTableHeader,
  hideLegend = false
}) {
  const [groupings, setGroupings] = useState([]);

  const [displayMode, setDisplayMode] = useState(displayModeInitial);

  const totals = useMemo(() => {
    // All Columns and then total column
    if (!data) return []
    const _totals = new Array(Object.keys(data.columns).length + 1).fill(0)

    Object.keys(data.data).map(row => {
      data.data[row].map((column, _i) => {
        _totals[_i] += column;

        if (excludeColumnsFromTotal.includes(Object.keys(data.columns)[_i])) {
          return (column, _i)
        }
        _totals[_totals.length-1] += column;
        
        return (column, _i)
      })
      return row
    })

    return _totals
  }, [data, excludeColumnsFromTotal]);

  const afterTotalTotals = useMemo(() => {
    // All Columns and then total column
    if (!data) return []
    if (!data.afterTotalColumns) return []
    const _totals = new Array(Object.keys(data.afterTotalColumns).length).fill(0)

    Object.keys(data.afterTotalData).map(row => {
      data.afterTotalData[row].map((column, _i) => {
        _totals[_i] += column;
        return (column, _i)
      })
      return row
    })

    return _totals
  }, [data]);

  useEffect(() => {
    request(true).get(groupingsEndpoint).then(e => {
      setGroupings(e.data)
    })
  }, [groupingsEndpoint])
  
  const handleDisplayMode = (func) => {
    if (parentHandler !== null) parentHandler(func)
    setDisplayMode(func)
  }

  return (
    <div>
      <div className="table-format" style={{display: 'grid', gridTemplateColumns: 'auto auto', gap: 15, marginBottom: 15}}>
        <div className={`data-row group-list ${selectedGroupBy?.title}-active`} style={{gridTemplateColumns: 'auto auto'}}>
          <GenericDataRow title={'Group by:'}>
            <DataGroupings
              group={selectedGroupBy}
              groupings={groupings}
              setGroup={setSelectectGroupBy}
            />
          </GenericDataRow>
        </div>
        <div className="data-row display-type">
          <select
            onChange={(_) => handleDisplayMode(_.target.value)}
            value={displayMode}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              backgroundColor: "white",
              paddingLeft: "10px",
            }}
          >
            {chartFunctions[0] ? (
              chartFunctions.map(func => (
                <>
                  <option value={`table-${func.type}`}>Table {func.display}</option>
                  <option value={`graph-${func.type}`}>Graph {func.display}</option>
                </>
              ))
            ) : (
              <>
                <option value="table">Table</option>
                <option value="graph">Graph</option>
              </>
            )}
          </select>
          <ProductChartInfo path={window.location.pathname} />
        </div>
      </div>
      <div className={`vcv-table-or-graph using-${displayMode}`} style={{minHeight: sizes.chartContainer}}>
        {!dataReady ? (
          <LoadingForGraphTable />
        ) : (
          <>
            {displayMode.includes("table") ? (
              <table className="table borders">
                <thead>
                  <tr>
                    <th></th>
                    <th colSpan={Object.keys(data.columns).length + (data.afterTotalColumns ? Object.keys(data.afterTotalColumns).length : 0) + 1} style={{ borderTopRightRadius: "5px" }}>
                      {overrideTableHeader ? overrideTableHeader : data.defaultGroup.replace('_', ' ')}
                    </th>
                  </tr>
                  <tr>
                    {
                      <>
                        <th>{selectedGroupBy?.title ?? ""}</th>
                        {
                          Object.keys(data.columns).map(a => {
                            
                            if (starColumnHeaders) {
                              const totalStars = Object.keys(data.columns).length - 1
                              let starsArray = []
                              for (const i of Array(totalStars).keys()) {
                                let star = (<i className={`fa-star ${i >= a ? "fal" : "fas"}`}/>)
                                starsArray.push(star)
                              }
                              return(
                                <th>
                                  {starsArray.map(star => star)}
                                </th>
                              )
                            }

                            return (
                              <th>
                                {a}
                              </th>
                            )
                          })
                        }
                      </>
                    }
                    <th className="no-radius bold">Total</th>
                    { data.afterTotalColumns ?
                      <>
                        {
                          Object.keys(data.afterTotalColumns).map(a => {
                            return (
                              <th>
                                {a}
                              </th>
                            )
                          })
                        }
                      </>
                      :
                      <></>
                    }
                  </tr>
                </thead>
                <tbody>
                  {data.keys.map((item) => {
                    let total = 0

                    for (let _totalIndex = 0; _totalIndex < Object.keys(data.columns).length; _totalIndex++) {
                      if (!data.data[item]) continue;
                      total += data.data[item][_totalIndex]
                    }

                    // data.data[item].forEach((totalRow, i) => {
                    //   totals[i] += data.data[item][i]
                    // })
                    // totals.push(total);

                    return (
                       item === "N/A" ?
                          <></>
                        :
                          <tr key={`summary-data-${Math.random()}`}>
                            <td>{item !== "" ? item : "Null"}</td>
                              {
                                new Array(Object.keys(data.columns).length).fill(0).map((_, i) => {
                                  return (
                                    <td>{prefix}{localised.format(data.data[item][i])}</td>
                                  )
                                })
                              }
                            <td className="bold">{prefix}{localised.format(total)}</td>
                              {data.afterTotalColumns ?
                              <> 
                              {
                                new Array(Object.keys(data.afterTotalColumns).length).fill(0).map((_, i) => {
                                  return (
                                    <td>{prefix}{localised.format(data.afterTotalData[item][i])}</td>
                                  )
                                })
                              }
                              </>
                              :
                              <></>
                              }
                          </tr>
                    );
                  })}
                  <tr key={`summary-data-${Math.random()}`} className="bold">
                    <td>Total</td>
                    {
                      new Array(Object.keys(data.columns).length + 1).fill(0).map((_, i) => {
                        return (
                          <td>{prefix}{localised.format(totals[i])}</td>
                        )
                      })
                    }
                    {data.afterTotalColumns ?
                      <> 
                          {
                            new Array(Object.keys(data.afterTotalColumns).length).fill(0).map((_, i) => {
                              return (
                                <td>{prefix}{localised.format(afterTotalTotals[i])}</td>
                              )
                            })
                          }
                      </>
                      : 
                      <></>
                    }
                  </tr>
                </tbody>
              </table>
            ) : (
              <ResponsiveContainer height={sizes.chart}>
                <BarChart data={data.graphColumns} barSize={50}>
                  { hideLegend ?
                    <></> 
                  :
                    <Legend iconType="circle" iconSize={11} margin={20} 
                    wrapperStyle={sizes.wrapperStyle} />
                  }
                  <XAxis {...xAxisProps} interval={0} tick={customBarTick ? customBarTick : {fontSize: sizes.chartXAxisFontSize}} dataKey="__columnName" />
                  <YAxis
                    type="number"
                    allowDecimals={false}
                    tick={{ fontSize: 10 }}
                    tickFormatter={(item) => {
                      return `${prefix}${localised.format(item)}`;
                    }}
                  />
                  <CartesianGrid />
                  {data.keys.map((item, index) => {
                    return (
                      <Bar
                        key={`graph_bar_${item}`}
                        dataKey={item}
                        fill={SharedChartColours[index % SharedChartColours.length]}
                        stackId={'a'}
                      />
                    );
                  })}
                  <Tooltip
                    cursor={false}
                    formatter={(value, name, props) => {
                      return localised.format(value);
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </>
        )}
      </div>
    </div>
  );
}

